import React from 'react';
/* import { useState, useEffect } from 'react'; */

function Header({ isDarkMode, onThemeToggle }) {
  return (
    <header className="site-header" style={{
      backgroundColor: isDarkMode ? '#121212' : '#f5f5f5'
    }}>
      <div className="logo" style={{
        color: isDarkMode ? 'white' : '#333'
      }}>
        🔮
      </div>
      <button 
        className="theme-toggle"
        onClick={onThemeToggle}
        style={{
          backgroundColor: isDarkMode ? '#333' : '#f0f0f0',
          color: isDarkMode ? 'white' : '#333'
        }}
      >
        {isDarkMode ? '☀️' : '🌙'}
      </button>
    </header>
  );
}

export default Header;
