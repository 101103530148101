import { useState, useEffect, useCallback, useRef } from 'react';
import { ethers } from 'ethers';
import { deploymentAddresses, IApi3ServerV1__factory } from '@api3/contracts';
import { CHAINS } from '@api3/chains';
import { ChainLogo } from '@api3/logos';
import './App.css';
import Header from './Components/Header';
import Footer from './Components/Footer';
import { CHAINLINK_ABI } from './data/chainlink/ABI.js';
import ETHEREUM_FEED_ADDRESSES from './data/chainlink/ethereumAddresses.js';
import POLYGON_FEED_ADDRESSES from './data/chainlink/polygonAddresses.js';
import { getPythPrices } from './data/pyth/client.js';

function App() {
  const [chainlinkPrice, setChainlinkPrice] = useState(null);
  const [api3Price, setApi3Price] = useState(null);
  const [chainlinkAvailable, setChainlinkAvailable] = useState(true);
  const [api3Available, setApi3Available] = useState(true);
  const [pythPrice, setPythPrice] = useState(null);
  const [pythAvailable, setPythAvailable] = useState(true);
  const [error, setError] = useState(null);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [feedSearch, setFeedSearch] = useState('');
  const [selectedFeed, setSelectedFeed] = useState('BTC/USD');
  const [selectedChain, setSelectedChain] = useState(CHAINS.find(chain => chain.id === '8453')); // Default to Base
  const [chainSearch, setChainSearch] = useState('');
  const [showChainDropdown, setShowChainDropdown] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const [isLoading, setIsLoading] = useState(false);
  
  // Refs for handling click outside
  const chainSelectorRef = useRef(null);
  const searchTimeoutRef = useRef(null);

  // Handle click outside chain dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (chainSelectorRef.current && !chainSelectorRef.current.contains(event.target)) {
        setShowChainDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Handle theme toggle
  const toggleTheme = useCallback(() => {
    setIsDarkMode((prev) => !prev);
  }, []);

  // Update theme attribute on body
  useEffect(() => {
    document.documentElement.setAttribute('data-theme', isDarkMode ? 'dark' : 'light');
  }, [isDarkMode]);

  // Debounced chain search
  const handleChainSearch = (value) => {
    setChainSearch(value);
    setHighlightedIndex(-1);
    setShowChainDropdown(true);

    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }

    searchTimeoutRef.current = setTimeout(() => {
      // Could add API call here if needed
    }, 300);
  };

  // Handle keyboard navigation
  const handleChainKeyDown = (e) => {
    if (!showChainDropdown) return;

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setHighlightedIndex(prev => 
          prev < filteredChains.length - 1 ? prev + 1 : prev
        );
        break;
      case 'ArrowUp':
        e.preventDefault();
        setHighlightedIndex(prev => prev > 0 ? prev - 1 : prev);
        break;
      case 'Enter':
        e.preventDefault();
        if (highlightedIndex >= 0 && highlightedIndex < filteredChains.length) {
          const selectedChain = filteredChains[highlightedIndex];
          handleChainSelect(selectedChain);
        }
        break;
      case 'Escape':
        setShowChainDropdown(false);
        break;
      default:
        break;
    }
  };

  // Handle chain selection
  const handleChainSelect = (chain) => {
    setSelectedChain(chain);
    setChainSearch(chain.name);
    setShowChainDropdown(false);
    setHighlightedIndex(-1);
  };

  // Filter chains based on search
  const filteredChains = CHAINS.filter(chain => 
    chain.name.toLowerCase().includes(chainSearch.toLowerCase()) ||
    chain.id.toLowerCase().includes(chainSearch.toLowerCase())
  );

  useEffect(() => {
    const fetchPrices = async () => {
      // Reset availability and prices at the start of each fetch
      setChainlinkAvailable(true);
      setApi3Available(true);
      setPythAvailable(true);
      setChainlinkPrice(null);
      setApi3Price(null);
      setPythPrice(null);
      setError(null);

      try {
        // Determine which network to use for Chainlink based on selected chain
        let chainlinkProvider;
        let feedAddresses;
        
        // Only set Chainlink data for supported networks
        if (selectedChain.id === '137') { // Polygon
          chainlinkProvider = new ethers.providers.JsonRpcProvider('https://polygon-rpc.com');
          feedAddresses = POLYGON_FEED_ADDRESSES;
        } else if (selectedChain.id === '1') { // Ethereum
          chainlinkProvider = new ethers.providers.JsonRpcProvider('https://eth.llamarpc.com');
          feedAddresses = ETHEREUM_FEED_ADDRESSES;
        } else {
          // Set Chainlink as unavailable for unsupported chains
          setChainlinkAvailable(false);
          feedAddresses = [];
        }
        
        // Connect to selected chain for API3
        if (!selectedChain?.providers?.[0]?.rpcUrl) {
          setApi3Available(false);
          throw new Error('No RPC URL available for selected chain');
        }
        const chainProvider = new ethers.providers.JsonRpcProvider(selectedChain.providers[0].rpcUrl);

        // Check if API3 contract is deployed on selected chain
        const api3ContractAddress = deploymentAddresses.Api3ServerV1[selectedChain.id];
        if (!api3ContractAddress) {
          setApi3Available(false);
        }

        // Find feed address for selected chain
        const priceFeed = feedAddresses.find(feed => feed.name === selectedFeed);
        if (!priceFeed) {
          setChainlinkAvailable(false);
        }

        // Initialize variables for price data
        let chainlinkData = null;
        let api3Data = null;

        // Fetch Chainlink price if available
        if (priceFeed) {
          try {
            const chainlinkContract = new ethers.Contract(
              priceFeed.address,
              CHAINLINK_ABI,
              chainlinkProvider
            );
            chainlinkData = await chainlinkContract.latestRoundData();
          } catch (err) {
            console.error('Chainlink fetch error:', err);
            setChainlinkAvailable(false);
          }
        }

        // Fetch API3 price if available
        if (api3ContractAddress) {
          try {
            const api3Contract = IApi3ServerV1__factory.connect(
              api3ContractAddress,
              chainProvider
            );
            const encodedFeedName = ethers.utils.formatBytes32String(selectedFeed);
            const dataFeedId = await api3Contract.dapiNameToDataFeedId(encodedFeedName);
            api3Data = await api3Contract.readDataFeedWithId(dataFeedId);
          } catch (err) {
            console.error('API3 fetch error:', err);
            setApi3Available(false);
          }
        }

        // Process Chainlink data if available
        if (chainlinkData && chainlinkData.answer) {
          const decimals = priceFeed?.decimals || 8;
          const chainlinkPriceUSD = parseFloat(ethers.utils.formatUnits(chainlinkData.answer, decimals));
          setChainlinkPrice(chainlinkPriceUSD);
          console.log('chainlink: ', chainlinkPriceUSD);
        }

        // Fetch Pyth price if available
        try {
          const pythData = await getPythPrices();
          
          // For BTC/USD, try known formats
          const feedParts = selectedFeed.split('/');
          const baseAsset = feedParts[0]; // e.g., "BTC"
          
          // Try all possible formats
          const pythSymbols = [
            `Crypto.${selectedFeed}`,
            `Crypto.${baseAsset}/USD`,
            `Crypto.${baseAsset}USD`
          ];
          
          let foundPrice = null;
          for (const symbol of pythSymbols) {
            if (pythData[symbol]) {
              foundPrice = pythData[symbol].usd;
              console.log('pyth:', foundPrice);
              break;
            }
          }
          
          if (foundPrice !== null) {
            setPythPrice(foundPrice);
          } else {
            setPythAvailable(false);
          }
        } catch (err) {
          console.error('Pyth fetch error:', err);
          setPythAvailable(false);
        }

        // Process API3 data if available
        if (api3Data && typeof api3Data.value !== 'undefined') {
          const api3BigNumber = ethers.BigNumber.from(api3Data.value);
          const api3PriceUSD = parseFloat(ethers.utils.formatUnits(api3BigNumber, 18));
          setApi3Price(api3PriceUSD);
          console.log('api3: ', api3PriceUSD);
        }
      } catch (err) {
        console.error('Error fetching prices:', err);
        setError(err.message || 'Error fetching prices. Please try again later.');
      }
    };

    setIsLoading(true);
    fetchPrices()
      .finally(() => setIsLoading(false));

    // Set up interval to fetch prices every 30 seconds
    const interval = setInterval(() => {
      setIsLoading(true);
      fetchPrices()
        .finally(() => setIsLoading(false));
    }, 30000);

    return () => clearInterval(interval);
  }, [selectedChain, selectedFeed]);

  return (
    <div className="App">
      <Header isDarkMode={isDarkMode} onThemeToggle={toggleTheme} />
      <div className="container">
        <h1>Oraclescan</h1>
        
        <div className="search-container" role="search">
          <div className="feed-search-wrapper">
            <input
              type="text"
              value={feedSearch}
              onChange={(e) => setFeedSearch(e.target.value)}
              placeholder="Search for a feed (e.g., BTC/USD, ETH/USD)"
              className="feed-search"
              aria-label="Search for price feed"
            />
            
            <div className="chain-selector" ref={chainSelectorRef}>
              <button 
                className={`chain-button ${showChainDropdown ? 'open' : ''}`}
                onClick={() => setShowChainDropdown(true)}
                aria-label={`Selected chain: ${selectedChain?.name}`}
              >
                <img 
                  src={selectedChain ? ChainLogo(selectedChain.id) : ''}
                  alt={`${selectedChain?.name} logo`}
                  className="chain-logo-button"
                />
              </button>
              {showChainDropdown && (
                <div className="chain-dropdown" role="listbox" id="chain-list">
                  <div className="chain-search-container">
                    <input
                      type="text"
                      value={chainSearch}
                      onChange={(e) => handleChainSearch(e.target.value)}
                      onKeyDown={handleChainKeyDown}
                      placeholder="Search chains"
                      className="chain-search"
                      aria-label="Search blockchain networks"
                      aria-expanded={showChainDropdown}
                      aria-controls="chain-list"
                      aria-activedescendant={highlightedIndex >= 0 ? `chain-${filteredChains[highlightedIndex]?.id}` : undefined}
                      role="combobox"
                    />
                  </div>
                {filteredChains.map((chain, index) => (
                  <div
                    key={chain.id}
                    id={`chain-${chain.id}`}
                    className={`chain-option ${highlightedIndex === index ? 'selected' : ''} ${selectedChain?.id === chain.id ? 'selected' : ''}`}
                    onClick={() => handleChainSelect(chain)}
                    role="option"
                    aria-selected={selectedChain?.id === chain.id}
                  >
                    <span>{chain.name}</span>
                    <span className="chain-id">({chain.id})</span>
                  </div>
                ))}
                {filteredChains.length === 0 && (
                  <div className="chain-option no-results">
                    No chains found
                  </div>
                )}
                </div>
              )}
            </div>

          </div>
          
          <button 
            onClick={() => setSelectedFeed(feedSearch)}
            disabled={isLoading}
            aria-busy={isLoading}
          >
            {isLoading ? 'Loading...' : 'Search'}
          </button>
        </div>

        {error ? (
          <div className="error">{error}</div>
        ) : (
          <div className="price-cards">
            <div className="price-card">
              <div className="oracle-name">API3</div>
              <img 
                src={selectedChain ? ChainLogo(selectedChain.id) : ''}
                alt={`${selectedChain?.name} logo`}
                className="chain-logo"
              />
              <h2>{selectedFeed}</h2>
              <div className="price">
                {!api3Available
                  ? 'Not Available'
                  : api3Price
                    ? `$${parseFloat(api3Price).toLocaleString(undefined, { minimumFractionDigits: 3, maximumFractionDigits: 3 })}`
                    : 'Loading...'}
              </div>
            </div>

            <div className="price-card">
              <div className="oracle-name">Chainlink</div>
              <img 
                src={selectedChain ? ChainLogo(selectedChain.id) : ''}
                alt={`${selectedChain?.name} logo`}
                className="chain-logo"
              />
              <h2>{selectedFeed}</h2>
              <div className="price">
                {!chainlinkAvailable
                  ? 'Not Available'
                  : chainlinkPrice
                    ? `$${chainlinkPrice.toLocaleString(undefined, { minimumFractionDigits: 3, maximumFractionDigits: 3 })}`
                    : 'Loading...'}
              </div>
            </div>

            <div className="price-card">
              <div className="oracle-name">Pyth</div>
              <img 
                src={selectedChain ? ChainLogo(selectedChain.id) : ''}
                alt={`${selectedChain?.name} logo`}
                className="chain-logo"
              />
              <h2>{selectedFeed}</h2>
              <div className="price">
                {!pythAvailable
                  ? 'Not Available'
                  : pythPrice
                    ? `$${parseFloat(pythPrice).toLocaleString(undefined, { minimumFractionDigits: 3, maximumFractionDigits: 3 })}`
                    : 'Loading...'}
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer isDarkMode={isDarkMode} />
    </div>
  );
}

export default App;
