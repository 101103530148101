import React from 'react';

function Footer({ isDarkMode }) {
  return (
    <footer className="site-footer" style={{
      backgroundColor: isDarkMode ? '#121212' : '#f5f5f5',
    }}>
      <div className="copyright" style={{
        color: isDarkMode ? 'white' : '#333'
      }}>
        © 2025 Oraclescan
      </div>
      <div className="footer-links">
        <a
          href="https://github.com/alikonuk1"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: isDarkMode ? 'white' : '#333',
            textDecoration: 'none',
          }}
        >
          GitHub
        </a>
        <a
          href="https://x.com/slot1024_eth"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: isDarkMode ? 'white' : '#333',
            textDecoration: 'none',
          }}
        >
          Twitter
        </a>
      </div>
    </footer>
  );
}

export default Footer;
