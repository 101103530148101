const ETHEREUM_FEED_ADDRESSES = [
    { name: '1INCH/ETH', address: '0x72AFAECF99C9d9C8215fF44C77B94B99C28741e8', decimals: 18 },
    { name: '1INCH/USD', address: '0xc929ad75B72593967DE83E7F7Cda0493458261D9' },
    { name: 'AAVE/USD', address: '0x547a514d5e3769680Ce22B2361c10Ea13619e8a9' },
    { name: 'AAVE/ETH', address: '0x6Df09E975c830ECae5bd4eD9d90f3A95a4f88012', decimals: 18 },
    { name: 'ALCX/ETH', address: '0x194a9AaF2e0b67c35915cD01101585A33Fe25CAa', decimals: 18 },
    { name: 'AMPL/USD', address: '0xe20CA8D7546932360e37E9D72c1a47334af57706' },
    { name: 'APE/ETH', address: '0xc7de7f4d4C9c991fF62a07D18b3E31e349833A18', decimals: 18 },
    { name: 'APE/USD', address: '0xD10aBbC76679a20055E167BB80A24ac851b37056' },
    { name: 'ARB/USD', address: '0x31697852a68433DbCc2Ff612c516d69E3D9bd08F' },
    { name: 'AUD/USD', address: '0x77F9710E7d0A19669A13c055F62cd80d313dF022' },
    { name: 'AVAIL/USD', address: '0xEBca574f1cE4d17cd02c20F47Ef8210C08Cc4255' },
    { name: 'AVAX/USD', address: '0xFF3EEb22B5E3dE6e705b44749C2559d704923FD7' },
    { name: 'Arbitrum Healthcheck', address: '0x32EaFC72772821936BCc9b8A32dC394fEFcDBfD9' },
    { name: 'BADGER/ETH', address: '0x58921Ac140522867bf50b9E009599Da0CA4A2379', decimals: 18 },
    { name: 'BAL/ETH', address: '0xC1438AA3823A6Ba0C159CfA8D98dF5A994bA120b' },
    { name: 'BAL/USD', address: '0xdF2917806E30300537aEB49A7663062F4d1F2b5F' },
    { name: 'BAT/ETH', address: '0x0d16d4528239e9ee52fa531af613AcdB23D88c94', decimals: 18 },
    { name: 'BNB/USD', address: '0x14e613AC84a31f709eadbdF89C6CC390fDc9540A' },
    { name: 'BTC/ETH', address: '0xdeb288F737066589598e9214E782fa5A8eD689e8', decimals: 18 },
    { name: 'BTC/USD', address: '0xF4030086522a5bEEa4988F8cA5B36dbC97BeE88c' },
    { name: 'C3M/EUR', address: '0xD41390267Afec3fA5b4c0B3aA6c706556CCE75ec' },
    { name: 'CAD/USD', address: '0xa34317DB73e77d453b1B8d04550c44D10e981C8e' },
    { name: 'CBETH/ETH', address: '0xF017fcB346A1885194689bA23Eff2fE6fA5C483b', decimals: 18 },
    { name: 'CHF/USD', address: '0x449d117117838fFA61263B61dA6301AA2a88B13A' },
    { name: 'CNY/USD', address: '0xeF8A4aF35cd47424672E3C590aBD37FBB7A7759a' },
    { name: 'COMP/ETH', address: '0x1B39Ee86Ec5979ba5C322b826B3ECb8C79991699', decimals: 18 },
    { name: 'COMP/USD', address: '0xdbd020CAeF83eFd542f4De03e3cF0C28A4428bd5' },
    { name: 'CRV/ETH', address: '0x8a12Be339B0cD1829b91Adc01977caa5E9ac121e', decimals: 18 },
    { name: 'CRV/USD', address: '0xCd627aA160A6fA45Eb793D19Ef54f5062F20f33f' },
    { name: 'CRVUSD/USD', address: '0xEEf0C605546958c1f899b6fB336C20671f9cD49F' },
    { name: 'CSPX/USD', address: '0xF4E1B57FB228879D057ac5AE33973e8C53e4A0e0' },
    { name: 'CVX/ETH', address: '0xC9CbF687f43176B302F03f5e58470b77D07c61c6', decimals: 18 },
    { name: 'CVX/USD', address: '0xd962fC30A72A84cE50161031391756Bf2876Af5D' },
    { name: 'Calculated XSUSHI/ETH', address: '0xF05D9B6C08757EAcb1fbec18e36A1B7566a13DEB' },
    { name: 'Consumer Price Index', address: '0x9a51192e065ECC6BDEafE5e194ce54702DE4f1f5' },
    { name: 'DAI/ETH', address: '0x773616E4d11A78F511299002da57A0a94577F1f4', decimals: 18 },
    { name: 'DAI/USD', address: '0xAed0c38402a5d19df6E4c03F4E2DceD6e29c1ee9' },
    { name: 'DPI/ETH', address: '0x029849bbc0b1d93b85a8b6190e979fd38F5760E2', decimals: 18 },
    { name: 'DPI/USD', address: '0xD2A593BF7594aCE1faD597adb697b5645d5edDB2' },
    { name: 'EIGEN/USD', address: '0xf2917e602C2dCa458937fad715bb1E465305A4A1' },
    { name: 'ENJ/ETH', address: '0x24D9aB51950F3d62E9144fdC2f3135DAA6Ce8D1B', decimals: 18 },
    { name: 'ENS/USD', address: '0x5C00128d4d1c2F4f652C267d7bcdD7aC99C16E16' },
    { name: 'ETH/BTC', address: '0xAc559F25B1619171CbC396a50854A3240b6A4e99' },
    { name: 'ETH/USD', address: '0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419' },
    { name: 'ETHFI/USD', address: '0x19678515847d8DE85034dAD0390e09c3048d31cd' },
    { name: 'EUR/USD', address: '0xb49f677943BC038e9857d61E7d053CaA2C1734C1' },
    { name: 'FBTC/BTC', address: '0xe5346a4Fd329768A99455d969724768a00CA63FB' },
    { name: 'FDUSD/USD', address: '0xfAA9147190c2C2cc5B8387B4f49016bDB3380572' },
    { name: 'FIL/ETH', address: '0x0606Be69451B1C9861Ac6b3626b99093b713E801', decimals: 18 },
    { name: 'FRAX/ETH', address: '0x14d04Fff8D21bd62987a5cE9ce543d2F1edF5D3E' },
    { name: 'FRAX/USD', address: '0xB9E1E3A9feFf48998E45Fa90847ed4D467E8BcfD' },
    { name: 'FTM/ETH', address: '0x2DE7E4a9488488e0058B95854CC2f7955B35dC9b', decimals: 18 },
    { name: 'FTT/ETH', address: '0xF0985f7E2CaBFf22CecC5a71282a89582c382EFE', decimals: 18 },
    { name: 'FXS/USD', address: '0x6Ebc52C8C1089be9eB3945C4350B68B8E4C2233f' },
    { name: 'GBP/USD', address: '0x5c0Ab2d9b5a7ed9f470386e82BB36A3613cDd4b5' },
    { name: 'GHO/USD', address: '0x3f12643D3f6f874d39C2a4c9f2Cd6f2DbAC877FC' },
    { name: 'GRT/ETH', address: '0x17D054eCac33D91F7340645341eFB5DE9009F1C1', decimals: 18 },
    { name: 'GRT/USD', address: '0x86cF33a451dE9dc61a2862FD94FF4ad4Bd65A5d2' },
    { name: 'HIGH/USD', address: '0x5C8D8AaB4ffa4652753Df94f299330Bb4479bF85' },
    { name: 'IB01/USD', address: '0x32d1463EB53b73C095625719Afa544D5426354cB' },
    { name: 'IBTA/USD', address: '0xd27e6D02b72eB6FCe04Ad5690C419196B4EF2885' },
    { name: 'IMX/USD', address: '0xBAEbEFc1D023c0feCcc047Bff42E75F15Ff213E6' },
    { name: 'JPY/USD', address: '0xBcE206caE7f0ec07b545EddE332A47C2F75bbeb3' },
    { name: 'KNC/ETH', address: '0x656c0544eF4C98A6a98491833A89204Abb045d6b', decimals: 18 },
    { name: 'KNC/USD', address: '0xf8fF43E991A81e6eC886a3D281A2C6cC19aE70Fc' },
    { name: 'KRW/USD', address: '0x01435677FB11763550905594A16B645847C1d0F3' },
    { name: 'LBTC/BTC', address: '0x5c29868C58b6e15e2b962943278969Ab6a7D3212' },
    { name: 'LDO/ETH', address: '0x4e844125952D32AcdF339BE976c98E22F6F318dB', decimals: 18 },
    { name: 'LINK/ETH', address: '0xDC530D9457755926550b59e8ECcdaE7624181557', decimals: 18 },
    { name: 'LINK/USD', address: '0x2c1d072e956AFFC0D435Cb7AC38EF18d24d9127c' },
    { name: 'LRC/ETH', address: '0x160AC928A16C93eD4895C2De6f81ECcE9a7eB7b4', decimals: 18 },
    { name: 'LUSD/USD', address: '0x3D7aE7E594f2f2091Ad8798313450130d0Aba3a0' },
    { name: 'MANA/ETH', address: '0x82A44D92D6c329826dc557c5E1Be6ebeC5D5FeB9', decimals: 18 },
    { name: 'MATIC/USD', address: '0x7bAC85A8a13A4BcD8abb3eB7d6b4d632c5a57676' },
    { name: 'MAVIA/USD', address: '0x29d26C008e8f201eD0D864b1Fd9392D29d0C8e96' },
    { name: 'MKR/ETH', address: '0x24551a8Fb2A7211A25a17B1481f043A8a8adC7f2', decimals: 18 },
    { name: 'MKR/USD', address: '0xec1D1B3b0443256cc3860e24a46F108e699484Aa' },
    { name: 'MLN/ETH', address: '0xDaeA8386611A157B08829ED4997A8A62B557014C', decimals: 18 },
    { name: 'NEIRO/USD', address: '0x771cf56aE75bC907193177237b423A9DA831280A' },
    { name: 'NZD/USD', address: '0x3977CFc9e4f29C184D4675f4EB8e0013236e5f3e' },
    { name: 'OETH/ETH', address: '0x703118C4CbccCBF2AB31913e0f8075fbbb15f563', decimals: 18 },
    { name: 'PAXG/USD', address: '0x9944D86CEB9160aF5C5feB251FD671923323f8C3' },
    { name: 'PERP/ETH', address: '0x3b41D5571468904D4e53b6a8d93A6BaC43f02dC9', decimals: 18 },
    { name: 'PHP/USD', address: '0x3C7dB4D25deAb7c89660512C5494Dc9A3FC40f78' },
    { name: 'PYUSD/USD', address: '0x8f1dF6D7F2db73eECE86a18b4381F4707b918FB1' },
    { name: 'PumpBTC/BTC Exchange Rate', address: '0x6CE4Ef3689F26edD40ed3ccbE3Cc29dab62C915f' },
    { name: 'RDNT/USD', address: '0x393CC05baD439c9B36489384F11487d9C8410471' },
    { name: 'REN/ETH', address: '0x3147D7203354Dc06D9fd350c7a2437bcA92387a4', decimals: 18 },
    { name: 'RETH/ETH', address: '0x536218f9E9Eb48863970252233c8F271f554C2d0', decimals: 18 },
    { name: 'RLUSD/USD', address: '0x26C46B7aD0012cA71F2298ada567dC9Af14E7f2A' },
    { name: 'RPL/USD', address: '0x4E155eD98aFE9034b7A5962f6C84c86d869daA9d' },
    { name: 'RSETH/ETH', address: '0x03c68933f7a3F76875C0bc670a58e69294cDFD01', decimals: 18 },
    { name: 'RSR/USD', address: '0x759bBC1be8F90eE6457C44abc7d443842a976d02' },
    { name: 'SAND/USD', address: '0x35E3f7E558C04cE7eEE1629258EcbbA03B36Ec56' },
    { name: 'SGD/USD', address: '0xe25277fF4bbF9081C75Ab0EB13B4A13a721f3E13' },
    { name: 'SHIB/ETH', address: '0x8dD1CD88F43aF196ae478e91b9F5E4Ac69A97C61', decimals: 18 },
    { name: 'SHV/USD', address: '0xc04611C43842220fd941515F86d1DDdB15F04e46' },
    { name: 'SKY/USD', address: '0xee10fE5E7aa92dd7b136597449c3d5813cFC5F18' },
    { name: 'SNX/ETH', address: '0x79291A9d692Df95334B1a0B3B4AE6bC606782f8c', decimals: 18 },
    { name: 'SNX/USD', address: '0xDC3EA94CD0AC27d9A86C180091e7f78C683d3699' },
    { name: 'SOL/USD', address: '0x4ffC43a60e009B551865A93d232E33Fce9f01507' },
    { name: 'SPELL/USD', address: '0x8c110B94C5f1d347fAcF5E1E938AB2db60E3c9a8' },
    { name: 'STETH/ETH', address: '0x86392dC19c0b719886221c78AB11eb8Cf5c52812', decimals: 18 },
    { name: 'STETH/USD', address: '0xCfE54B5cD566aB89272946F602D76Ea879CAb4a8' },
    { name: 'STG/USD', address: '0x7A9f34a0Aa917D438e9b6E630067062B7F8f6f3d' },
    { name: 'SUSHI/ETH', address: '0xe572CeF69f43c2E488b33924AF04BDacE19079cf', decimals: 18 },
    { name: 'SUSHI/USD', address: '0xCc70F09A6CC17553b2E31954cD36E4A2d89501f7' },
    { name: 'SWELL/ETH', address: '0x2a638b1203a3B62FF003598B7165Fc5cd5b13B00', decimals: 18 },
    { name: 'Secured Overnight Financing Rate', address: '0xBc8f5e8B47D69fa10A4B64eC5B45b466f81B77CA' },
    { name: 'SolvBTC.BBN/SolvBTC Exchange Rate', address: '0x1f34794A16D644b9810477EbF3f0b3870141E2e3' },
    { name: 'TAO/USD', address: '0x1c88503c9A52aE6aaE1f9bb99b3b7e9b8Ab35459' },
    { name: 'TBTC/USD', address: '0x8350b7De6a6a2C1368E7D4Bd968190e13E354297' },
    { name: 'TRY/USD', address: '0xB09fC5fD3f11Cf9eb5E1C5Dba43114e3C9f477b5' },
    { name: 'TUSD/ETH', address: '0x3886BA987236181D98F2401c507Fb8BeA7871dF2', decimals: 18 },
    { name: 'TUSD/USD', address: '0xec746eCF986E2927Abd291a2A1716c940100f8Ba' },
    { name: 'Total Marketcap/USD', address: '0xEC8761a0A73c34329CA5B1D3Dc7eD07F30e836e2' },
    { name: 'UNI/ETH', address: '0xD6aA3D25116d8dA79Ea0246c4826EB951872e02e', decimals: 18 },
    { name: 'UNI/USD', address: '0x553303d460EE0afB37EdFf9bE42922D8FF63220e' },
    { name: 'USD0/USD', address: '0x7e891DEbD8FA0A4Cf6BE58Ddff5a8ca174FebDCB' },
    { name: 'USD0++/USD', address: '0xFC9e30Cf89f8A00dba3D34edf8b65BCDAdeCC1cB' },
    { name: 'USDC/ETH', address: '0x986b5E1e1755e3C2440e960477f25201B0a8bbD4', decimals: 18 },
    { name: 'USDC/USD', address: '0x8fFfFfd4AfB6115b954Bd326cbe7B4BA576818f6' },
    { name: 'USDM/USD', address: '0x079674468Fee6ab45aBfE986737A440701c49BdB' },
    { name: 'USDP/USD', address: '0x09023c0DA49Aaf8fc3fA3ADF34C6A7016D38D5e3' },
    { name: 'USDS/USD', address: '0xfF30586cD0F29eD462364C7e81375FC0C71219b1' },
    { name: 'USDT/ETH', address: '0xEe9F2375b4bdF6387aa8265dD4FB8F16512A1d46' },
    { name: 'USDT/USD', address: '0x3E7d1eAB13ad0104d2750B8863b489D65364e32D' },
    { name: 'USDe/USD', address: '0xa569d910839Ae8865Da8F8e70FfFb0cBA869F961' },
    { name: 'WBTC/BTC', address: '0xfdFD9C85aD200c506Cf9e21F1FD8dd01932FBB23' },
    { name: 'XAG/USD', address: '0x379589227b15F1a12195D3f2d90bBc9F31f95235' },
    { name: 'XAU/USD', address: '0x214eD9Da11D2fbe465a6fc601a91E62EbEc1a0D6' },
    { name: 'XCN/USD', address: '0xeb988B77b94C186053282BfcD8B7ED55142D3cAB' },
    { name: 'YFI/ETH', address: '0x7c5d4F8345e66f68099581Db340cd65B078C41f4', decimals: 18 },
    { name: 'YFI/USD', address: '0xA027702dbb89fbd58938e4324ac03B58d812b0E1' },
    { name: 'ZBU/USD', address: '0x617689cAB8329d57fEa64f4C086190E6797b8B5e' },
    { name: 'ZKsync Healthcheck', address: '0x987517b50230De2042750570D8fE3783ee115975' },
    { name: 'ZRX/ETH', address: '0x2Da4983a622a8498bb1a21FaE9D8F6C664939962', decimals: 18 },
    { name: 'ZRX/USD', address: '0x2885d15b8Af22648b98B122b22FDF4D2a56c6023' },
    { name: 'cbBTC/USD', address: '0x2665701293fCbEB223D11A08D826563EDcCE423A' },
    { name: 'deUSD/USD', address: '0x471a6299C027Bd81ed4D66069dc510Bd0569f4F8' },
    { name: 'ezETH/ETH', address: '0x636A000262F6aA9e1F094ABF0aD8f645C44f641C', decimals: 18 },
    { name: 'mETH/ETH', address: '0x5b563107C8666d2142C216114228443B94152362', decimals: 18 },
    { name: 'pufETH/ETH', address: '0xDe3f7Dd92C4701BCf59F47235bCb61e727c45f80', decimals: 18 },
    { name: 'rsETH/ETH Exchange Rate', address: '0x9d2F2f96B24C444ee32E57c04F7d944bcb8c8549', decimals: 18 },
    { name: 'rswETH/ETH', address: '0xb613CfebD0b6e95abDDe02677d6bC42394FdB857', decimals: 18 },
    { name: 'sUSDe/USD', address: '0xFF3BC18cCBd5999CE63E788A1c250a88626aD099' },
    { name: 'sUSDe/USD Calculated', address: '0xeD9960f685C3c4d6aa937E56169a41C19D0aC9c6' },
    { name: 'solvBTC/BTC', address: '0x936B31C428C29713343E05D631e69304f5cF5f49' },
    { name: 'stBTC/BTC Exchange Rate', address: '0xD93571A6201978976e37c4A0F7bE17806f2Feab2' },
    { name: 'stETH PoR', address: '0xAd410E655C0fE4741F573152592eeb766e686CE7' },
    { name: 'tETH/wstETH Exchange Rate', address: '0x7B2Fb2c667af80Bccc0B2556378352dFDE2be914' },
    { name: 'uniBTC/BTC Exchange Rate', address: '0x861d15F8a4059cb918bD6F3670adAEB1220B298f' },
    { name: 'weETH/ETH', address: '0x5c9C449BbC9a6075A2c061dF312a35fd1E05fF22', decimals: 18 },
    { name: 'wstETH/USD Calculated', address: '0x164b276057258d81941e97B0a900D4C7B358bCe0' }
  ];

export default ETHEREUM_FEED_ADDRESSES;
