import { Connection } from '@solana/web3.js';
import { PythHttpClient, getPythClusterApiUrl, getPythProgramKeyForCluster } from '@pythnetwork/client';

const pythPublicKey = getPythProgramKeyForCluster('pythnet');
const connection = new Connection(getPythClusterApiUrl('pythnet'));
const pythClient = new PythHttpClient(connection, pythPublicKey);

export const getPythPrices = async () => {
  try {
    const result = { _ts: Date.now() };
    
    const data = await pythClient.getData();
    
    // Store prices for each symbol
    for (const symbol of data.symbols) {
      try {
        const price = data.productPrice.get(symbol);
        if (price && price.price !== undefined) {
          result[symbol] = {
            symbol: symbol,
            usd: price.price,
            value: price.price,
            // Adding additional price info for debugging
            conf: price.conf,
            status: price.status,
            ts: Date.now()
          };
        }
      } catch (err) {
        console.warn(`Could not get price for ${symbol}:`, err);
      }
    }

    return result;
  } catch (error) {
    console.error('Pyth price fetch error:', error.message);
    console.error('Full error:', error);
    return {};
  }
};
