const POLYGON_FEED_ADDRESSES = [
    { name: '1INCH/USD', address: '0x443C5116CdF663Eb387e72C688D276e702135C87' },
    { name: 'AAPL/USD', address: '0x7E7B45b08F68EC69A99AAb12e42FcCB078e10094' },
    { name: 'AAVE/ETH', address: '0xbE23a3AA13038CfC28aFd0ECe4FdE379fE7fBfc4', decimals: 18 },
    { name: 'AAVE/USD', address: '0x72484B12719E23115761D5DA1646945632979bB6' },
    { name: 'AAVE Network Emergency Count (Polygon)', address: '0xDAFA1989A504c48Ee20a582f2891eeB25E2fA23F' },
    { name: 'ADA/USD', address: '0x882554df528115a743c4537828DA8D5B58e52544' },
    { name: 'ALCX/USD', address: '0x5DB6e61B6159B20F068dc15A47dF2E5931b14f29' },
    { name: 'ALGO/USD', address: '0x03Bc6D9EFed65708D35fDaEfb25E87631a0a3437' },
    { name: 'AMZN/USD', address: '0xf9184b8E5da48C19fA4E06f83f77742e748cca96' },
    { name: 'APE/USD', address: '0x2Ac3F3Bfac8fC9094BC3f0F9041a51375235B992' },
    { name: 'AUD/USD', address: '0x062Df9C4efd2030e243ffCc398b652e8b8F95C6f' },
    { name: 'AVAX/USD', address: '0xe01eA2fbd8D76ee323FbEd03eB9a8625EC981A10' },
    { name: 'AXS/USD', address: '0x9c371aE34509590E10aB98205d2dF5936A1aD875' },
    { name: 'BADGER/ETH', address: '0x82C9d4E88862f194C2bd874a106a90dDD0D35AAB', decimals: 18 },
    { name: 'BADGER/USD', address: '0xF626964Ba5e81405f47e8004F0b276Bb974742B5' },
    { name: 'BAL/ETH', address: '0x03CD157746c61F44597dD54C6f6702105258C722', decimals: 18 },
    { name: 'BAL/USD', address: '0xD106B538F2A868c28Ca1Ec7E298C3325E0251d66' },
    { name: 'BAT/USD', address: '0x2346Ce62bd732c62618944E51cbFa09D985d86D2' },
    { name: 'BCH/USD', address: '0x327d9822e9932996f55b39F557AEC838313da8b7' },
    { name: 'BNB/USD', address: '0x82a6c4AF830caa6c97bb504425f6A66165C2c26e' },
    { name: 'BRL/USD', address: '0xB90DA3ff54C3ED09115abf6FbA0Ff4645586af2c' },
    { name: 'BTC/ETH', address: '0x19b0F0833C78c0848109E3842D34d2fDF2cA69BA', decimals: 18 },
    { name: 'BTC/USD', address: '0xc907E116054Ad103354f2D350FD2514433D57F6f' },
    { name: 'BTC-USD Total Marketcap', address: '0x18E4058491C3F58bC2f747A9E64cA256Ed6B318d' },
    { name: 'CAD/USD', address: '0xACA44ABb8B04D07D883202F99FA5E3c53ed57Fb5' },
    { name: 'CBETH/ETH', address: '0x0a6a03CdF7d0b48d4e4BA8e362A4FfC3aAC4f3c0', decimals: 18 },
    { name: 'CHF/USD', address: '0xc76f762CedF0F78a439727861628E0fdfE1e70c2' },
    { name: 'CHZ/USD', address: '0x2409987e514Ad8B0973C2b90ee1D95051DF0ECB9' },
    { name: 'CNY/USD', address: '0x04bB437Aa63E098236FA47365f0268547f6EAB32' },
    { name: 'COMP/USD', address: '0x2A8758b7257102461BC958279054e372C2b1bDE6' },
    { name: 'COP/USD', address: '0xfAA9147190c2C2cc5B8387B4f49016bDB3380572' },
    { name: 'CRV/ETH', address: '0x1CF68C76803c9A415bE301f50E82e44c64B7F1D4', decimals: 18 },
    { name: 'CRV/USD', address: '0x336584C8E6Dc19637A5b36206B1c79923111b405' },
    { name: 'CVX/USD', address: '0x5ec151834040B4D453A1eA46aA634C1773b36084' },
    { name: 'Calculated MaticX/USD', address: '0x5d37E4b374E6907de8Fc7fb33EE3b0af403C7403' },
    { name: 'Calculated stMATIC/USD', address: '0x97371dF4492605486e23Da797fA68e55Fc38a13f' },
    { name: 'DAI/ETH', address: '0xFC539A559e170f848323e19dfD66007520510085', decimals: 18 },
    { name: 'DAI/USD', address: '0x4746DeC9e833A82EC7C2C1356372CcF2cfcD2F3D' },
    { name: 'DASH/USD', address: '0xD94427eDee70E4991b4b8DdCc848f2B58ED01C0b' },
    { name: 'DGB/USD', address: '0x4205eC5fd179A843caa7B0860a8eC7D980013359' },
    { name: 'DODO/USD', address: '0x59161117086a4C7A9beDA16C66e40Bdaa1C5a8B6' },
    { name: 'DOGE/USD', address: '0xbaf9327b6564454F4a3364C33eFeEf032b4b4444' },
    { name: 'DOGE-USD Total Marketcap', address: '0xbd238a35Fb47aE22F0cC551f14ffB8E8f04FCA21' },
    { name: 'DOT/USD', address: '0xacb51F1a83922632ca02B25a8164c10748001BdE' },
    { name: 'DPI/ETH', address: '0xC70aAF9092De3a4E5000956E672cDf5E996B4610', decimals: 18 },
    { name: 'DPI/USD', address: '0x2e48b7924FBe04d575BA229A59b64547d9da16e9' },
    { name: 'EOS/USD', address: '0xd6285F06203D938ab713Fa6A315e7d23247DDE95' },
    { name: 'ETC/USD', address: '0xDf3f72Be10d194b58B1BB56f2c4183e661cB2114' },
    { name: 'ETH/USD', address: '0xF9680D99D6C9589e2a93a78A04A279e509205945' },
    { name: 'ETH-USD Total Marketcap', address: '0x67935f65D1577ced9f4929D3679A157E95C1c02c' },
    { name: 'EUR/USD', address: '0x73366Fe0AA0Ded304479862808e02506FE556a98' },
    { name: 'FB/USD', address: '0x5b4586C911144A947D7814Fd71fe0872b8334748' },
    { name: 'FIL/USD', address: '0xa07703E5C2eD1516107c7c72A494493Dcb99C676' },
    { name: 'FRAX/USD', address: '0x00DBeB1e45485d53DF7C2F0dF1Aa0b6Dc30311d3' },
    { name: 'FTM/USD', address: '0x58326c0F831b2Dbf7234A4204F28Bba79AA06d5f' },
    { name: 'FTT/USD', address: '0x817A7D43f0277Ca480AE03Ec76Fc63A2EC7114bA' },
    { name: 'FXS/USD', address: '0x6C0fe985D3cAcbCdE428b84fc9431792694d0f51' },
    { name: 'GBP/USD', address: '0x099a2540848573e94fb1Ca0Fa420b00acbBc845a' },
    { name: 'GHST/ETH', address: '0xe638249AF9642CdA55A92245525268482eE4C67b', decimals: 18 },
    { name: 'GHST/USD', address: '0xDD229Ce42f11D8Ee7fFf29bDB71C7b81352e11be' },
    { name: 'GNS/USD', address: '0x9cb43aa3D036Cb035a694Ba0AAa91f8875B16cE1' },
    { name: 'GOOGL/USD', address: '0x1b32682C033b2DD7EFdC615FA82d353e254F39b5' },
    { name: 'GRT/USD', address: '0x3FabBfb300B1e2D7c9B84512fe9D30aeDF24C410' },
    { name: 'HBAR/USD', address: '0xC5878bDf8a89FA3bF0DC8389ae8EE6DE601D01bC' },
    { name: 'ICP/USD', address: '0x84227A76a04289473057BEF706646199D7C58c34' },
    { name: 'ILS/USD', address: '0x8d5eB34C509261533235b91350d359EdcB969D33' },
    { name: 'ILV/ETH', address: '0x3636B780588328dc3F5df075De5627DBc9A6BA10', decimals: 18 },
    { name: 'INR/USD', address: '0xDA0F8Df6F5dB15b346f4B8D1156722027E194E60' },
    { name: 'JPY/USD', address: '0xD647a6fC9BC6402301583C91decC5989d8Bc382D' },
    { name: 'KAVA/USD', address: '0x7899dd75C329eFe63e35b02bC7d60D3739FB23c5' },
    { name: 'KNC/USD', address: '0x10e5f3DFc81B3e5Ef4e648C4454D04e79E1E41E2' },
    { name: 'KRW/USD', address: '0x24B820870F726dA9B0D83B0B28a93885061dbF50' },
    { name: 'LINK/ETH', address: '0xb77fa460604b9C6435A235D057F7D319AC83cb53', decimals: 18 },
    { name: 'LINK/MATIC', address: '0x5787BefDc0ECd210Dfa948264631CD53E68F7802' },
    { name: 'LINK/USD', address: '0xd9FFdb71EbE7496cC440152d43986Aae0AB76665' },
    { name: 'LTC/USD', address: '0xEB99F173cf7d9a6dC4D889C2Ad7103e8383b6Efa' },
    { name: 'MANA/USD', address: '0xA1CbF3Fe43BC3501e3Fc4b573e822c70e76A7512' },
    { name: 'MATIC/ETH', address: '0x327e23A4855b6F663a28c5161541d69Af8973302', decimals: 18 },
    { name: 'MATIC/USD', address: '0xAB594600376Ec9fD91F8e885dADF0CE036862dE0' },
    { name: 'MKR/ETH', address: '0x807b59d12520830D1864286FA0271c27baa94197', decimals: 18 },
    { name: 'MKR/USD', address: '0xa070427bF5bA5709f70e98b94Cb2F435a242C46C' },
    { name: 'MLN/ETH', address: '0xB89D583B72aBF9C3a7e6e093251C2fCad3365312', decimals: 18 },
    { name: 'MSFT/USD', address: '0xC43081d9EA6d1c53f1F0e525504d47Dd60de12da' },
    { name: 'MXN/USD', address: '0x171b16562EA3476F5C61d1b8dad031DbA0768545' },
    { name: 'NZD/USD', address: '0xa302a0B8a499fD0f00449df0a490DedE21105955' },
    { name: 'OGN/USD', address: '0x8Ec0eC2e0F26D8253ABf39Db4B1793D76B49C6D5' },
    { name: 'OHM Index', address: '0xc08f70c26ab8C659EaF259c51a0F7ae22758c6ac' },
    { name: 'OM/USD', address: '0xc86105DccF9BD629Cea7Fd41f94c6050bF96D57F' },
    { name: 'PAXG/USD', address: '0x0f6914d8e7e1214CDb3A4C6fbf729b75C69DF608' },
    { name: 'PHP/USD', address: '0x218231089Bebb2A31970c3b77E96eCfb3BA006D1' },
    { name: 'PLN/USD', address: '0xB34BCE11040702f71c11529D00179B2959BcE6C0' },
    { name: 'QNT/USD', address: '0xF7F291042F6Cbc4deC0Ad75c17786511a530dbe8' },
    { name: 'QUICK/USD', address: '0x2251169D32E7538652a9a8c86bf0c43bFcd956f1' },
    { name: 'SAND/USD', address: '0x3D49406EDd4D52Fb7FFd25485f32E073b529C924' },
    { name: 'SEK/USD', address: '0xbd92B4919ae82be8473859295dEF0e778A626302' },
    { name: 'SGD/USD', address: '0x8CE3cAc0E6635ce04783709ca3CC4F5fc5304299' },
    { name: 'SHIB/USD', address: '0x3710abeb1A0Fc7C2EC59C26c8DAA7a448ff6125A' },
    { name: 'SLP/USD', address: '0xBB3eF70953fC3766bec4Ab7A9BF05B6E4caf89c6' },
    { name: 'SNX/USD', address: '0xbF90A5D9B6EE9019028dbFc2a9E50056d5252894' },
    { name: 'SOL/USD', address: '0x10C8264C0935b3B9870013e057f330Ff3e9C56dC' },
    { name: 'SPY.US', address: '0x187c42f6C0e7395AeA00B1B30CB0fF807ef86d5d' },
    { name: 'STORJ/USD', address: '0x0F1d5Bd7be9B30Fc09E110cd6504Bd450e53cb0E' },
    { name: 'SUSHI/ETH', address: '0x17414Eb5159A082e8d41D243C1601c2944401431', decimals: 18 },
    { name: 'SUSHI/USD', address: '0x49B0c695039243BBfEb8EcD054EB70061fd54aa0' },
    { name: 'THB/USD', address: '0x5164Ad28fb12a5e55946090Ec3eE1B748AFb3785' },
    { name: 'THETA/USD', address: '0x38611b09F8f2D520c14eA973765C225Bf57B9Eac' },
    { name: 'TRX/USD', address: '0x307cCF7cBD17b69A487b9C3dbe483931Cf3E1833' },
    { name: 'TRY/USD', address: '0xd78325DcA0F90F0FFe53cCeA1B02Bb12E1bf8FdB' },
    { name: 'TSLA/USD', address: '0x567E67f456c7453c583B6eFA6F18452cDee1F5a8' },
    { name: 'TUSD/USD', address: '0x7C5D415B64312D38c56B54358449d0a4058339d2' },
    { name: 'UMA/USD', address: '0x33D9B1BAaDcF4b26ab6F8E83e9cb8a611B2B3956' },
    { name: 'UNI/ETH', address: '0x162d8c5bF15eB6BEe003a1ffc4049C92114bc931', decimals: 18 },
    { name: 'UNI/USD', address: '0xdf0Fb4e4F928d2dCB76f438575fDD8682386e13C' },
    { name: 'USDC/ETH', address: '0xefb7e6be8356cCc6827799B6A7348eE674A80EaE', decimals: 18 },
    { name: 'USDC/USD', address: '0xfE4A8cc5b5B2366C1B58Bea3858e81843581b2F7' },
    { name: 'USDS/USD', address: '0x3B21a277babcB73b5a2325106FC04Df8AA1aC5b6' },
    { name: 'USDT/ETH', address: '0xf9d5AAC6E5572AEFa6bd64108ff86a222F69B64d', decimals: 18 },
    { name: 'USDT/USD', address: '0x0A6513e40db6EB1b165753AD52E80663aeA50545' },
    { name: 'USDe/USD', address: '0x5c3890e86f3E7Ed7F5390532De147953580f1605' },
    { name: 'WBTC/ETH', address: '0xA338e0492B2F944E9F8C0653D3AD1484f2657a37', decimals: 18 },
    { name: 'WBTC/USD', address: '0xDE31F8bFBD8c84b5360CFACCa3539B938dd78ae6' },
    { name: 'WOO/USD', address: '0x6a99EC84819FB7007dd5D032068742604E755c56' },
    { name: 'WSTETH/ETH', address: '0x10f964234cae09cB6a9854B56FF7D4F38Cda5E6a', decimals: 18 },
    { name: 'XAG/USD', address: '0x461c7B8D370a240DdB46B402748381C3210136b3' },
    { name: 'XAU/USD', address: '0x0C466540B2ee1a31b441671eac0ca886e051E410' },
    { name: 'XLM/USD', address: '0x692AE5510cA9070095A496dbcFBCDA99D4024Cd9' },
    { name: 'XMR/USD', address: '0xBE6FB0AB6302B693368D0E9001fAF77ecc6571db' },
    { name: 'XPT/USD', address: '0x76631863c2ae7367aF8f37Cd10d251DA7f1DE186' },
    { name: 'XRP/USD', address: '0x785ba89291f676b5386652eB12b30cF361020694' },
    { name: 'XTZ/USD', address: '0x691e26AB58ff05800E028b0876A41B720b26FC65' },
    { name: 'YFI/ETH', address: '0x9896A1eA7A00F5f32Ab131eBbeE07487B0af31D0', decimals: 18 },
    { name: 'YFI/USD', address: '0x9d3A43c111E7b2C6601705D9fcF7a70c95b1dc55' },
    { name: 'ZEC/USD', address: '0xBC08c639e579a391C4228F20d0C29d0690092DF0' },
    { name: 'ibBTC PricePerShare', address: '0xc3E676E68dB28c9Fb2199f25B60560723237cc76' },
    { name: 'sUSDe/USD', address: '0xe71A04ACe06e2667c7e1c802E998CBf44A6bf53C' },
    { name: 'sUSDe/USDe Exchange Rate', address: '0xd1d85Bf11c28F878EE75BdC39387a2a15E0390b7' },
    { name: 'wstETH-stETH Exchange Rate', address: '0x3Ea1eC855fBda8bA0396975eC260AD2e9B2Bc01c' }
  ];

export default POLYGON_FEED_ADDRESSES;
