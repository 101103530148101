export const CHAINLINK_ABI = [
    {
      inputs: [],
      name: 'latestRoundData',
      outputs: [
        {
          name: 'roundId',
          type: 'uint80'
        },
        { name: 'answer', type: 'uint256' },
        { name: 'startedAt', type: 'uint256' },
        { name: 'updatedAt', type: 'uint256' },
        { name: 'answeredInRound', type: 'uint80' }
      ],
      stateMutability: 'view',
      type: 'function'
    }
  ];
